body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: #4a4a4a;
}

p {
  line-height: 1.5;
}

h1 {
  letter-spacing: 0.05rem;
  font-size: 1.125em;
  font-weight: 300;
  padding: 0;
}

h2 {
  font-size: 2em;
  font-weight: 100;
}
